<template>
  <v-card class="mx-auto" height="100vh" width="100%">
    <v-navigation-drawer absolute dark src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg" width="100%"
      permanent>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            SupportMeow.com
          </v-list-item-title>
          <v-list-item-subtitle>
            Admin area
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-item @click="linkToCustomers()">
          <v-list-item-icon>
            <v-icon>account_circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Customers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>

</template>



<script>

  export default {
    data: () => ({
    }),
    methods: {
      linkToCustomers() {
        this.$router.push({
          name: 'customers'
        })
      }
    }
  }
</script>

<style>

</style>