<template>

  <v-container class="grey lighten-5" fluid fill-height pa-0>
    <v-row no-gutters>
      <v-col cols="12" sm="3">
        <Drawer />
      </v-col>
      <v-col class="content-view" cols="12" sm="9">
        <NavBar />
        <router-view :key="$route.fullPath" style="padding: 20px;" />
      </v-col>
    </v-row>
      <v-snackbar
      :value="this.$store.state.emailVerification"
      :timeout='-1'
      absolute
      bottom
      center
      color="blue-grey"
      v-if="this.$store.state.emailVerification"
    >
    <span style="text-align: center;">{{this.verificationText}}&nbsp; &nbsp;</span>
      <v-btn v-if="this.emailSent == false" color="success" @click="sendEmail()">Resend Email</v-btn>
      <v-btn v-if="this.emailSent == true" color="success" outlined>Email sent!</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
  import NavBar from '../components/layout/NavBar.vue';
  import Drawer from '../components/layout/Drawer.vue';

  export default {
    name: 'Home',
    components: {
      Drawer,
      NavBar
    },
    data: () => ({
      verificationText: 'Please make sure you verify your email address.',
      emailSent: false
    }),
    computed: {
      getUser () {
        return this.store.state.user
      }
    },
    methods: {
      sendEmail() {
        var user = window.firebase.auth().currentUser;
        user.sendEmailVerification().then(() => {
          // Email sent.
          this.emailSent = true;
          this.verificationText = 'Check your email address!'
        }).catch((error) => {
          console.log('email verification error: ', error)
          // An error happened.
          this.emailSent = false;
          this.verificationText = error.message;
        });
      }
    }
  };
</script>

<style scoped>
.content-view {
  max-height: 100vh;
  overflow-y: auto;
}
</style>