import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Home from './views/Home.vue'
import Admin from './views/Admin.vue'
import SignUp from './components/auth/SignUp.vue'
import Login from './components/auth/Login.vue'
import ForgotPassword from './components/auth/ForgotPassword.vue'
import Plans from './components/home/Plans.vue'
import ViewProfile from './components/profile/ViewProfile.vue'
import AddNewSite from './components/home/AddNewSite.vue'
import Settings from './components/home/Settings.vue'
import Site from './components/home/Site.vue'
import Setup from './components/home/Setup.vue'
/* Admin */
import Customers from './components/admin/Customers.vue'

import firebase from 'firebase'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '*', redirect: '/login' },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      meta: {
        requiresAdmin: true
      },
      children: [
        {
          path: '/admin/customers',
          name: 'customers',
          component: Customers,
          meta: {
            requiresAdmin: true
          }
        }
      ]
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/',
          name: 'AddNewSiteHome',
          component: AddNewSite,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/settings',
          name: 'settings',
          component: Settings,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/setup',
          name: 'setup',
          component: Setup,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/plans',
          name: 'plans',
          component: Plans,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/profile',
          name: 'profile',
          component: ViewProfile,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/add-a-new-site',
          name: 'AddNewSite',
          component: AddNewSite,
          meta: {
            requiresAuth: true
          }
        },
        { path: '/site/:siteId', 
        component: Site, 
        props: true,
        meta: {
          requiresAuth: true
        }
      }
      ]
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUp
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/forgotpassword',
      name: 'forgotpassword',
      component: ForgotPassword
    },
    
  ]
})

router.beforeResolve((to, from, next) => {
   // check to see if route requires admin
    if (to.matched.some(rec => rec.meta.requiresAdmin)) {
    let user = firebase.auth().currentUser

    if (user) {
    firebase.firestore().collection('users').doc(user.uid).get().then((userData) => {
      let data = userData.data()
      if (data.admin) {
      store.commit('updateUser', { user:user })
        next()
      } 
      if (!data.admin) {
      next( { name: 'login' } )
      }
    })
  } else {
    next( { name: 'login' } )

  }
  }
  // check to see if route requires auth
  
  if (to.matched.some(rec => rec.meta.requiresAuth)) {
    // check auth state of user
    let user = firebase.auth().currentUser
    if (user) {
      store.commit('updateUser', { user })
      store.dispatch('commitSites');
      // check if user has entered setup information
      // user signed in, proceed to route
      // if not done setup, redirect to there until it is done
      
      if (to.path !== '/setup' && !user.displayName) {
        next('/setup')
      } else {
        next()
      }
    } else {
      // no user signed in, redirect to login
      next('/login')

      store.commit('logOutUser', null )
      store.commit('unSetSites', null )
    }
  } else {
    next()
  }
})

export default router;
