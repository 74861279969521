<template>
    <v-app-bar
      class="app-bar"
      color="deep-green accent-4"
      dark
    >
      <div class="flex-grow-1"></div>
       <v-list class="top-right-list">
         <v-list-item  v-if="$store.state.user"
            @click="$router.push({name: 'profile'})"
          ><v-icon>person</v-icon>
          <v-list-item-title>&nbsp;{{$store.state.user.email}}</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="logout"
          > <v-icon>exit_to_app</v-icon>
            <v-list-item-title>&nbsp;Sign Out</v-list-item-title>
          </v-list-item>
        </v-list>
    </v-app-bar>
</template>

<script>
import firebase from 'firebase';

export default {
    name: 'Navbar',
    data() {
        return {

        }
    },
    methods: {
        logout() {
            firebase.auth().signOut().then(() => {
            this.$store.commit('logOutUser', null )
            this.$router.push({ name: 'login'})
            })
        }
    }
}
</script>

<style scoped>
.top-right-list {
  display: inline-flex;
}
.app-bar {
    background: url('~@/assets/header.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
.theme--dark.v-list {
    background: none;
    color: #FFFFFF;
}

</style>