<template>
  <v-card class="drawer-menu mx-auto" height="100vh" width="100%">
    <v-navigation-drawer absolute dark src="@/assets/drawer.png" width="100%"
      permanent>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            SupportMeow.com
          </v-list-item-title>
          <v-list-item-subtitle>
            Customer area
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list class="supported-sites">
        <v-row style="margin-left: 5px;">
          <v-col style="margin-top: 10px;">
            <strong>Supported Sites</strong>
          </v-col>
          <v-col>
            <v-btn color="success" @click="addNew()" style="margin-top: 2px; margin-right: 10px;"><v-icon>keyboard_backspace</v-icon>&nbsp;Add new</v-btn>
          </v-col>
        </v-row>
        <v-list-item v-for="(item, docId) in $store.getters.sites" :key="docId" @click="($router.push('/site/' + docId))">
          <v-list-item-content>
            <v-list-item-title>{{item.site}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>

</template>


<script>

  export default {
    data: () => ({
    }),
    methods: {
      addNew() {
        this.$router.push({
          name: 'AddNewSite'
        })
      }
    }
  }
</script>

<style>
.drawer-menu {
    border-radius: 0px;
}
.v-navigation-drawer__border {
    background-color: rgba(255, 255, 255, 0) !important;
}
   /* Drawer Scrollbar*/
        .drawer-menu ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #F5F5F5;
    }

    .drawer-menu ::-webkit-scrollbar {
        width: 15px;
        background-color: #F5F5F5;
    }

    .drawer-menu ::-webkit-scrollbar-thumb {
        background-color: #fff;
        background-image: -webkit-linear-gradient(top,
                #0b2540 0%,
                #348bc0 100%);
    }
    /* Drawer Scrollbar End*/
</style>