<template>
  <div>
    <h2>Your profile</h2>
    <div class="your-profile" style="margin-top: 30px;">
      <h3 style="margin-bottom: 20px;">Basic Information</h3>
      <v-form>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field v-model="email" label="Your Email" readonly hint="Sorry! Can't change this one!" outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field v-model="name" label="Your Name*" outlined></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field v-model="company" label="Company Name" hint="This will appear on your invoices." outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field v-model="address1" label="Address*" outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field v-model="city" label="City*" outlined></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field v-model="state" label="State*" outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field v-model="country" label="Country*" outlined>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-alert v-if="feedback" type="success">
              {{feedback}}
            </v-alert>
            <v-alert v-if="showerr" type="error">
              {{showerr}}
            </v-alert>
            <v-btn color="success" @click="updateCustomerInfo" large style="height: 50px; float: left;">Update info</v-btn>
          </v-col>

        </v-row>
      </v-form>
      <div class="cards-container">
        <h3 style="margin-bottom: 10px;">Payment methods</h3>
        <v-row v-if="payment_methodsLoader" class="loading">
        <div style="margin-top: 0px; margin-left: 12px;">
        <v-progress-circular indeterminate color="green"></v-progress-circular>
        <span style="color: black;">&nbsp;&nbsp;Loading...</span>
        </div>
        </v-row>
        <v-row class="cards" v-if="this.payment_methods" style="margin: 0px;">
          <v-col v-for="(item, id) in this.payment_methods.data" :key="id" cols="12" sm="5" class="card"
            style="background: white; margin: 0px 10px 10px 0px; border: solid 1px #000;">
            <v-row>
              <v-col cols="2" class="card-brand-img">
                <img v-if="item.card.brand == 'mastercard'" width="100%"
                  src="@/assets/credit-card-brands/mc_symbol.svg">
                <img v-else-if="item.card.brand == 'visa'" width="100%"
                  src="@/assets/credit-card-brands/200px-Visa.png">
                <img v-else-if="item.card.brand == 'discover'" width="100%"
                  src="@/assets/credit-card-brands/Discover-logo.jpg">
                <img v-else-if="item.card.brand == 'american express'" width="100%"
                  src="@/assets/credit-card-brands/amex-card.svg">
                <img v-else-if="item.card.brand == 'diners club'" width="100%"
                  src="@/assets/credit-card-brands/diners_club_international.png">
                <img v-else-if="item.card.brand == 'jcb'" width="100%" src="@/assets/credit-card-brands/JCB_logo.png">
                <img v-else-if="item.card.brand == 'unionpay'" width="100%"
                  src="@/assets/credit-card-brands/UnionPay_logo.png">
                <img v-else width="100%" src="@/assets/credit-card-brands/Credit-Card-Icon.png">
              </v-col>
              <v-col cols="10">
                <div class="card-title">{{item.card.brand | capitalize }} **** {{item.card.last4}}</div>
                <div class="card-subtitle-title">Expires {{item.card.exp_month}}/{{item.card.exp_year}}<span v-if="(item.id == stripe.invoice_settings.default_payment_method)" style="float: right; color: #4CAF50; margin-top: -13px;">Active</span></div>
              </v-col>
              <v-row class="actions" justify="space-between" style="margin: 0px 10px 10px 10px">
                <v-btn v-if="(item.id !== stripe.invoice_settings.default_payment_method)" color="primary" small @click="setDefaultCreditCard(item.id)">Set as
                  default</v-btn>
                <v-btn v-if="(item.id !== stripe.invoice_settings.default_payment_method)" color="#e7e3e3" small @click="deleteCreditCard(item.id)">Remove</v-btn>
              </v-row>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row class="stripe-container" style="margin-bottom: 20px;">
              <v-col class="credit-card">
                <v-expansion-panels focusable>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Add new credit card</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="stripe-card" style="margin-top: 20px; max-width: 450px">
                        <StripeElements
                          :stripe-key="stripeKey"
                          :instance-options="instanceOptions"
                          :elements-options="elementsOptions"
                          #default="{ elements }"
                          ref="elms"
                        >
                          <StripeElement
                            type="card"
                            :elements="elements"
                            :options="cardOptions"
                            ref="card"
                            :class='{ complete }'
                            @change='complete = $event.complete'
                          />
                        </StripeElements>
                      <v-btn class='pay-with-stripe' @click="submitNewCreditCard" type="button" style="margin-top: 20px;" color="success" :disabled='!complete'>Save Card</v-btn>
                      </div>
                      <div v-if="submitNewCreditCardLoading" class="loading" style="margin: 20px;">
                        <v-progress-circular indeterminate color="green"></v-progress-circular>
                        <span style="color: black;">&nbsp;&nbsp;Loading...</span>
                      </div>
                      <v-alert v-if="submitNewCreditCardSuccess" style="margin-top: 20px; color: white;"
                        color="success">Card Saved!</v-alert>
                      <v-alert v-if="submitNewCreditCardErr" style="margin-top: 20px; color: white;" color="error">
                        {{submitNewCreditCardErr}}</v-alert>
                    </v-expansion-panel-content>
                    <!-- old
                    <v-expansion-panel-content>
                      <card style="margin-top: 20px" class='stripe-card' :class='{ complete }'
                        stripe='pk_live_r4MjJVNbZfM6b6vmABW4jwCf00t3cAwIz5' :options='stripeOptions'
                        @change='complete = $event.complete' />
                      <v-btn id="card-button" :data-secret="client_secret" class='pay-with-stripe'
                        style="margin-top: 20px;" color="success" @click='submitNewCreditCard' :disabled='!complete'>
                        Save Card</v-btn>
                      <div v-if="submitNewCreditCardLoading" class="loading" style="margin: 20px;">
                        <v-progress-circular indeterminate color="green"></v-progress-circular>
                        <span style="color: black;">&nbsp;&nbsp;Loading...</span>
                      </div>
                      <v-alert v-if="submitNewCreditCardSuccess" style="margin-top: 20px; color: white;"
                        color="success">Card Saved!</v-alert>
                      <v-alert v-if="submitNewCreditCardErr" style="margin-top: 20px; color: white;" color="error">
                        {{submitNewCreditCardErr}}</v-alert>
                    </v-expansion-panel-content>-->
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <h3 style="margin-bottom: 10px;">My Subscriptions</h3>
      <v-data-table
      :headers="subscription_headers"
      :items="customerSubscriptions"
      >
      <template v-slot:[`item.created`]="props">
        {{props.item.created | formatDate}}
      </template>
      <template v-slot:[`item.current_period_end`]="props">
        {{props.item.current_period_end | formatDate}}
      </template>
      <template v-slot:[`item.items.data[0].plan`]="props">
        <div class="plan-details">
        <span><strong>{{props.item.items.data[0].plan.metadata.name || 'Plan details'}}</strong></span><br>
        <span v-if="props.item.items.data[0].plan.metadata.basic !== '0'">Basic: ${{props.item.items.data[0].plan.metadata.basic}}<br></span>
        <span v-if="props.item.items.data[0].plan.metadata.ecommerce_support !== '0'">Ecommerce support: ${{props.item.items.data[0].plan.metadata.ecommerce_support}}<br></span>
        <span><strong>Total:</strong> {{props.item.items.data[0].plan.amount | centsToDollars}} </span>
        </div>
      </template>
      <template v-slot:[`item.actions`]="props">
        <v-btn text small @click="cancelDialog = !cancelDialog"><v-icon>close</v-icon>Cancel plan</v-btn><br>
        <div v-if="cancelDialog">Are you sure? We will remove data from our system.<br>
          <div class="cancel-plan-buttons" v-if="!delete_subLoader" style="margin-top: 5px;">
          <v-btn x-small color="error" @click="deleteSubscription(props.item)">Yes, delete it</v-btn>
          <v-btn @click="cancelDialog = !cancelDialog" x-small color="success" style="margin-left: 10px;">No, keep it</v-btn>
          </div>
          <v-row v-if="delete_subLoader" class="loading">
          <div style="margin-top: 10px; margin-left: 12px; padding-bottom: 20px;">
          <v-progress-circular indeterminate color="green"></v-progress-circular>
          <span style="color: black;">&nbsp;&nbsp;Loading...</span>
          </div>
          </v-row>
        </div>

      </template>
      </v-data-table>
      <h3 style="margin-bottom: 10px; margin-top: 20px;">Invoices</h3>
      <v-card>
    <v-data-table
      :headers="invoice_headers"
      :items="customerInvoices"
    >
    <template v-slot:[`item.created`]="props">
        {{props.item.created | formatDate}}
    </template>
    <template v-slot:[`item.amount_paid`]="props">
        {{props.item.amount_paid | centsToDollars}}
    </template>
    <template v-slot:[`item.amount_remaining`]="props">
        {{props.item.amount_remaining | centsToDollars}}
    </template>
    <template v-slot:[`item.paid`]="props">
        <div v-if="props.item.paid"><v-icon color="success">check</v-icon></div>
        <div v-if="!props.item.paid"><v-icon color="red">clear</v-icon></div>
    </template>
    <template v-slot:[`item.invoice_pdf`]="props">
        <a :href="props.item.invoice_pdf" target="_blank"><v-icon color="red">picture_as_pdf</v-icon></a>
    </template>
    <template v-slot:[`item.hosted_invoice_url`]="props">
        <a :href="props.item.hosted_invoice_url" target="_blank"><v-icon>pageview</v-icon></a>
    </template>
    </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
  import firebase from '@/firebase.js'
  import moment from 'moment'
  import { StripeElements, StripeElement} from 'vue-stripe-elements-plus'

  export default {
    name: 'ViewProfile',
    data() {
      return {
        email: null,
        name: null,
        company: null,
        address1: null,
        city: null,
        state: null,
        country: null,
        coupon: null,
        feedback: null,
        showerr: null,
        stripe: {},
        payment_methods: {},
        default_payment_method: null,
        payment_methodsLoader: true,
        delete_subLoader: false,
        complete: false,
        client_secret: null,
        stripeKey: null,
        cancelDialog: false,
        instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
        },
        elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
        },
        cardOptions: {
          style: {
            base: {
              color: '#000',
              '::placeholder': {
                color: '#000',
              },
            },
            empty: {
              color: '#000'
            },
            invalid: {
              color: 'red',
              ':focus': {
                color: 'red',
              },
            },
          }

          // see https://stripe.com/docs/stripe.js#element-options for details
        },
        submitNewCreditCardLoading: false,
        submitNewCreditCardSuccess: false,
        submitNewCreditCardErr: false,
        invoice_headers: [
          { text: 'Invoice number', value: 'number'},
          { text: 'Created', value: 'created'},
          { text: 'Amount paid', value: 'amount_paid' },
          { text: 'Amount remaining', value: 'amount_remaining' },
          { text: 'Paid', value: 'paid' },
          { text: 'PDF', value: 'invoice_pdf'},
          { text: 'Details', value: 'hosted_invoice_url'}
        ],
        customerInvoices: [],
        subscription_headers: [
          { text: 'Created', value: 'created'},
          { text: 'Next payment', value: 'current_period_end'},
          { text: 'Plan', value: 'items.data[0].plan'},
          { text: 'Actions', value: 'actions'}
        ],
        customerSubscriptions: []
      }
    },
    components: {
      StripeElements,
      StripeElement
    },
    methods: {
      updateCustomerInfo() {
        // this needs validation and stripe customer update.
        let user = firebase.auth().currentUser.uid
        let payload = {
          name: this.name,
          company: this.company,
          address_line1: this.address1,
          address_city: this.city,
          address_state: this.state,
          address_country: this.country,
          user: user
        }
        let updateCustomerInfo = firebase.functions().httpsCallable('updateCustomerInfo')

        return updateCustomerInfo(payload).then(() => {
          this.feedback = "Info updated"
        }).catch(err => {
          this.feedback = '';
          this.showerr = 'Error: ', err;
        })

      },
      setupIntent() {
        /* Setup intent */
        let setupIntent = firebase.functions().httpsCallable('setupIntent')
        setupIntent().then((result) => {
          this.client_secret = result.data.client_secret;
        })
        /* Setup intent end */
      },
      getCreditCards() {
        let user = firebase.auth().currentUser
        let listCustomerPaymentMethods = firebase.functions().httpsCallable('listCustomerPaymentMethods')
        return listCustomerPaymentMethods({
          userUid: user.uid
        }).then((response) => {
          this.payment_methodsLoader = false
          this.payment_methods = response.data;
        })
      },
      submitNewCreditCard() {

      let user = firebase.auth().currentUser

      this.submitNewCreditCardSuccess = false
      this.submitNewCreditCardErr = false
      this.submitNewCreditCardLoading = true;

      const groupComponent = this.$refs.elms
      const cardComponent = this.$refs.card
      // Get stripe element
      const cardElement = cardComponent.stripeElement


      groupComponent.instance.handleCardSetup(this.client_secret, cardElement).then((result) => {
      console.log('handlecardsetup result: ', result)
      if (result.error) {
      // Display error.message in your UI.
      this.submitNewCreditCardErr = result.error.message;
      this.submitNewCreditCardLoading = false;
      } else {
          let addNewCreditCard = firebase.functions().httpsCallable('addNewCreditCard')
          let StripeCustomerData = {
            setupIntent: result.setupIntent,
            user: user.uid,
            email: user.email
          }
          addNewCreditCard(StripeCustomerData).then((data) => {
            this.submitNewCreditCardLoading = false;
            if (data.data == 'card saved') {
              this.getCreditCards();
              this.setupIntent();

              return this.submitNewCreditCardSuccess = true;
            } else {
              return this.submitNewCreditCardErr = 'Sorry! Could not add a credit card!';
            }
          })
      }
      })
      },
      deleteCreditCard(card) {
        let deleteCreditCard = firebase.functions().httpsCallable('deleteCreditCard')
        deleteCreditCard(card).then(() => {
          this.getCreditCards();
        })
      },
      setDefaultCreditCard(card) {
        let user = firebase.auth().currentUser.uid
        let setDefaultCreditCard = firebase.functions().httpsCallable('setDefaultCreditCard')

        let payload = {
          card: card,
          user: user
        }
      setDefaultCreditCard(payload).then(() => {
          this.getCreditCards();
          this.getCustomerData();
        })
      },
      getCustomerData() {
      let user = firebase.auth().currentUser.uid
      let getCustomerData = firebase.functions().httpsCallable('getCustomerData')
  
      getCustomerData({
        userUid: user
      }).then((response) => {
        
        this.stripe = response.data;
      })
      },
      getCustomerInvoices() {
      let user = firebase.auth().currentUser.uid
      let getCustomerInvoices = firebase.functions().httpsCallable('getCustomerInvoices')
      
      getCustomerInvoices(user).then((payload) => {
        this.customerInvoices = payload.data.data;
      })
      },
      listCustomerSubscriptions() {
      let user = firebase.auth().currentUser.uid
      let listCustomerSubscriptions = firebase.functions().httpsCallable('listCustomerSubscriptions')

      listCustomerSubscriptions(user).then((payload) => {
        
        this.customerSubscriptions = payload.data.data;

      })
      },
      deleteSubscription(payload) {

        this.delete_subLoader = true;

        let data = {
          subId: payload.id,
          siteId: payload.metadata.site_id,
          planId: payload.plan.id
        }

        let deleteSubscription = firebase.functions().httpsCallable('deleteSubscription')
        deleteSubscription(data).then(() => {
        this.listCustomerSubscriptions();
        this.delete_subLoader = false;
        })
      }
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      },
      formatDate(value) {
        if (!value) return ''
        return moment.unix(value).format("L");
      },
      centsToDollars(value) {
        return '$' + (value / 100)
      }
    },
    created() {

      let user = firebase.auth().currentUser
      let userRef = firebase.firestore().collection('users').doc(user.uid)

      let getStripePublic = firebase.functions().httpsCallable('getStripePublic')
      getStripePublic().then((result) => {
        this.stripeKey = result.data;
        this.stripeLoaded = true;
      })

      userRef.get().then((user) => {
        let userData = user.data()
        this.email = userData.email
        this.name = userData.name
        this.company = userData.company || ''
        this.address1 = userData.address_line1
        this.city = userData.address_city
        this.state = userData.address_state
        this.country = userData.address_country
      })
      this.getCustomerData();
      this.setupIntent();
      this.getCreditCards();
      this.getCustomerInvoices();
      this.listCustomerSubscriptions();
     
    }
  }
</script>

<style scoped>
  .your-profile .col-sm-6.col-12 {
    max-height: 75px;
  }

  .v-expansion-panel--active .v-expansion-panel-header {
    background-color: #c4ffc4;
  }

  .card-brand-img {
    margin: auto;
  }
</style>