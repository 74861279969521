<template>
  <div class="add-new-site">
    <v-form ref="form" @keydown.native.enter.prevent="validate" v-model="valid" lazy-validation>
      <v-alert v-if="feedback" type="error" style="margin-top: 20px;">
        {{feedback}}
      </v-alert>
      <v-row>
      <v-col cols="12" sm="6">
      <h2>Add A New Site To Be Supported:</h2>      
      <v-text-field v-model="site" :rules="siteRules" label="Add a site url..." required></v-text-field>
      <v-row>
      <v-col>
        <v-switch color="success" :value="60" v-model="extra.security" label="Extra Security (+$60)"></v-switch>
        <v-switch color="success" :value="60" v-model="extra.optimization" label="Speed + Mobile Optimization (+$60)"></v-switch>
        <div class="text-left" style="margin-top: 8px;">
        <v-btn color="blue lighten-1" dark @click="showCoupon = !showCoupon">I have a coupon&nbsp;<v-icon>loyalty</v-icon></v-btn>
        <v-row class="couponbox" style="margin: auto; text-align: center;" v-show="showCoupon">
        <v-col cols="8">
        <v-text-field placeholder="Coupon Code" v-model="couponId"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn color="success" v-if="!couponLoading" style="margin-top: 10px;" @click="retrieveCoupon(couponId)">Save&nbsp;<v-icon>check_circle</v-icon></v-btn>
          <div class="loading" v-if="couponLoading" style="margin: 20px;">
              <v-progress-circular indeterminate color="green"></v-progress-circular>
          </div>
        </v-col>
        </v-row>
            <v-row v-if="couponError">
              <v-col cols="12">
              <v-alert  color="error" dark>{{couponError}}</v-alert>
              </v-col>
            </v-row>
        </div>
      </v-col>
      </v-row>
      </v-col>
      <v-col cols="12" sm="6" style="background-color: rgb(219,250,255); padding: 70px 50px 50px 80px;">
        <div class="summary-title">
          <h3>Here Is What We Will Do Every Month:</h3>
        </div>
        <v-row class="added-features" style="margin-top: 10px;">
          <ul>
            <li>Weekly WP Updates</li>
            <li>Website health check</li>
            <li>Weekly WordPress Core Update</li>
            <li>Weekly WordPress Plugin Updates</li>
            <li>Weekly WordPress Theme Updates</li>
            <li>Emergency Support + 1 Hour A Month Website Edits</li>
            <li>Daily secure off-site backup of database and site files</li>
            <li>Send you reports how things are going with your site</li>
            <li v-if="this.extra.security">Unlimited Website Edits</li>
            <li v-if="this.extra.security">Security Optimization</li>
            <li v-if="this.extra.security">Periodical security audits and reports</li>
            <li v-if="this.extra.security">iThemes Security Pro Premium or StackPath</li>
            <li v-if="this.extra.optimization">Unlimited Website Edits</li>
            <li v-if="this.extra.optimization">Speed Optimization</li>
            <li v-if="this.extra.optimization">Mobile And Tablet Optimization</li>
            <li v-if="this.extra.optimization">Image And Media Optimization</li>
          </ul>
        </v-row>
        <v-row class="package" style="font-weight: 400; font-size: 1em">
        <v-col>
        Package total: ${{packageCost}} per month<br>
        </v-col>
        </v-row>
        <v-row v-if="couponData.id">
          <v-col>
            <span color="success" style="color: green;">You are saving {{couponData.percent_off}}% </span>
            <span color="success" style="color: green;">by using coupon '{{couponData.id}}'. </span>
            <span v-if="couponData.duration == 'repeating'" color="success" style="color: green;">Discount applies for {{couponData.duration_in_months}} months.</span>
          </v-col>
        </v-row>
        <v-row class="total" style="font-weight: 500; font-size: 1.4em">
        <v-col>
        Total: ${{totalCost}} per month<br>
        </v-col>
        </v-row>
        <v-row class="add-website">
        <v-col>
        <v-btn :disabled="!valid" v-if="!loading" large color="success" class="mr-4" style="margin-top: 0px;" @click="validate">
        Add New Website&nbsp;<v-icon>arrow_right_alt</v-icon>
       </v-btn>
       <div class="terms" style="margin-top: 20px;">
        <strong style="font-size: 15px;">Recurring billing, cancel anytime.</strong><br>
         <div style="margin-top: 10px; font-size: 12px;">By clicking 'Add new website', your payment will be charged from your credit card, and your subscription will automatically renew for the same package lenth at the same price until you cancel it in the settings. By clicking 'Add new website', you agree to our <a href="https://supportmeow.com/terms-of-service/" target="_blank">Terms.</a></div>
       </div>
       <div class="loading" v-if="loading" style="margin: 20px;">
              <v-progress-circular indeterminate color="green"></v-progress-circular>
              <span>&nbsp;&nbsp;Loading...</span>
            </div>
        </v-col>
      </v-row>
      </v-col>
      </v-row>

    </v-form>

  </div>
</template>

<script>
  import firebase from '@/firebase.js'

  export default {
    name: 'AddNewSite',
    data: () => ({
      site: '',
      valid: true,
      feedback: false,
      loading: false,
      showCoupon: false,
      couponLoading: false,
      base: 65,
      extra: {
        security: 0,
        optimization: 0
      },
      couponId: '528zt4sH',
      couponData: false,
      couponError: false,
      amount: 0,
      siteRules: [
        v => !!v || 'Site url is required'
      ]
    }),
    filters: {

    },
    created() {
      this.retrieveCoupon(this.couponId)
    },
    methods: {
      validate() {

        if (this.site.includes(".") && (this.site.length >= 4)) {
          this.loading = true;

          var filterSite = this.site;
          filterSite = filterSite.replace(/^https?:\/\//, '')
          var user = firebase.auth().currentUser;

          if (user) {
            let addUserData = firebase.functions().httpsCallable('addNewSite')
            addUserData({
              site: filterSite,
              uid: user.uid,
              extra_security: this.extra.security,
              extra_optimization: this.extra.optimization,
              couponId: this.couponData.id || null
            }).then((siteId) => {
              this.$router.push('/site/' + siteId.data.addSiteRefId)
            }).catch(err => {
              this.feedback = 'Unexpected error happened: ' + err;
            })
          }
        } else {
          this.loading = false;
          this.feedback = 'Please fill in the correct site URL';
        }
      },
      retrieveCoupon(payload) {
        let retrieveCoupon = firebase.functions().httpsCallable('retrieveCoupon');
        this.couponLoading = true;
        retrieveCoupon({coupon: payload}).then(coupon => {

         this.couponLoading = false;

          this.couponData = coupon.data;
          

          if (coupon.data.id) {
            this.couponError = false;
          }
          if (coupon.data.raw.message) {
            this.couponError = coupon.data.raw.message;
          }

        }
        
        )

      }
    },
    computed: {
      
      packageCost() {
        return (this.base + this.extra.security + this.extra.optimization)
      },
      totalCost() {
        let total = this.base + this.extra.security + this.extra.optimization;

        if (this.couponData.id) {
        let percentToSmallUnits = this.couponData.percent_off / 100

        let totalOff = total * percentToSmallUnits;

        total = (total - totalOff).toFixed(2)

        }
        return total
      }
    }
  }
</script>

<style scoped>
.v-input >>> label {
  color: black;
}
</style>
