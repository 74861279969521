import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import firebase from 'firebase'
import VueChatScroll from 'vue-chat-scroll'

Vue.use(VueChatScroll)

Vue.config.productionTip = false

window.app = null

window.firebase = firebase;
// let app = null;

// wait for firebase auth to init before creating the app
firebase.auth().onAuthStateChanged((auth) => {

  // init app if not already created
  if (!window.app) {
    window.app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app') 
  }
  if (auth.currentUser && !auth.emailVerified) {
    firebase.auth().currentUser.reload();
    
    setTimeout(checkerVar, 5000)

}



async function checkVerification() {
  await firebase.auth().currentUser.reload();
  let askAuthAgain = firebase.auth().currentUser;

  if (!askAuthAgain.emailVerified && store && askAuthAgain.displayName) {
    // show email verification toggle, but only when setup card has been completed.
    store.commit('askEmailVerification', true)


  }
  if (askAuthAgain.emailVerified) {
    store.commit('askEmailVerification', false)
    clearIt()
  }
}

function clearIt() {
  clearInterval(checkerVar)
}

let checkerVar = setInterval(() => {
  checkVerification()}, 10000)
})