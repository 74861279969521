<template>
    <div class="Site" v-if="site">
        <v-row>
            <v-col>
                <h3>Message and email log for site: {{site.site}}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="support-box" col="12" sm="8">
                <ul class="messages" v-chat-scroll="{always: true, smooth: false, scrollonremoved:true}">
                    <li class="message" v-for="(item, index) in messages" :key="index">
                        <div v-if="(item.uid == $store.state.user.uid)" class="mine">
                            <div class="user" style="float: left; margin-bottom: 10px">{{item.name}}
                            </div>
                            <div class="timestamp"
                                style="color: rgb(178, 182, 195); float: right; margin-bottom: 10px; clear: right;">
                                {{item.date | formatDate}}</div>
                            <div class="reply" v-html="item.reply">{{item.reply}}</div>
                        </div>
                        <div v-if="(item.uid !== $store.state.user.uid)" class="not-mine">
                            <div class="user" style="float: left; margin-bottom: 10px">{{item.name}}
                            </div>
                            <div class="timestamp"
                                style="color: rgb(178, 182, 195); float: right; margin-bottom: 10px; clear: right;">
                                {{item.date | formatDate}}</div>
                            <div class="reply" v-html="item.reply">{{item.reply}}</div>
                        </div>
                    </li>
                </ul>
                <div class="new-message">
                    <!-- ctrl + enter here later for new row. -->
                    <v-form class="send-reply">
                        <v-alert v-if="feedback" type="error" style="margin-top: 20px;">
                            {{feedback}}
                        </v-alert>
                        <v-textarea @keydown="chatKeys($event)" v-model="reply" required filled
                            label="Type your message here" color="success" rows="3" row-height="25" auto-grow
                            style="margin: 5px 20px 0px 20px;">
                        </v-textarea>
                        <v-btn @click="addMessage()" style="float: right" color="success"><v-icon>reply</v-icon>&nbsp;Reply</v-btn>

                    </v-form>
                </div>
            </v-col>
            <v-col col="12" sm="4">
                <v-row justify="center" class="status" style="padding: 0px 0px 10px 0px;">
                    <v-chip v-if="site.status == 'awaiting_customer'" class="ma-2" color="success" outlined>
                        <v-icon left>trending_flat</v-icon>
                        Ticket Status: Awaiting customer
                    </v-chip>
                    <v-chip v-if="site.status == 'received_and_in_progress'" class="ma-2" color="success" outlined>
                        <v-icon left>trending_flat</v-icon>
                        Ticket Status: Received and in progress
                    </v-chip>
                    <div style="text-align: center; font-size: 10px; width: 100%; clear: both;" v-if="site.status == 'received_and_in_progress'">You will receive email when we reply</div>

                </v-row>
                <v-row justify="center" v-if="isAdmin">
                    <v-col col="11">
                        <v-select :items='statusSelects' label="Pick a status" @change="changeStatus"></v-select>
                        <v-alert v-if="statusFeedback" type="success" style="margin-top: 10px;">
                            {{statusFeedback}}
                        </v-alert>
                        <v-alert v-if="statusFeedbackErr" type="error" style="margin-top: 10px;">
                            {{statusFeedback}}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-expansion-panels v-model="panel">
                        <v-expansion-panel>
                            <v-expansion-panel-header>Site access details</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div style="color: #000; padding-bottom: 10px;">Leave us your site access
                                    details. That can include wordpress user, password, ftp or a hosting panel. Ask more
                                    details in the support chat and we help you to aquire these things.</div>
                                <v-textarea outlined name="input-7-4" color="success" v-model="accessDetails">
                                </v-textarea>
                                <v-alert v-if="accessDetailsFeedback" type="success" style="margin-top: 10px;">
                                    {{accessDetailsFeedback}}
                                </v-alert>
                                <v-alert v-if="accessDetailsFeedbackErr" type="error" style="margin-top: 10px;">
                                    {{accessDetailsFeedbackErr}}
                                </v-alert>
                                <v-btn color="success" style="float: right" @click="saveAccessDetails()">Save</v-btn>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Work log</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <ul class="work-log"
                                    v-chat-scroll="{always: true, smooth: false, scrollonremoved:true}">
                                    <li>
                                        We periodically update this when we work on your site.
                                    </li>
                                    <li v-for="(item, index) in workLog" :key="index">
                                        <strong>{{item.date | formatDate}}</strong><br>{{item.entry}}
                                    </li>
                                </ul>
                                <div class="new-entry" v-if="isAdmin">
                                    <h3 style="margin-top: 15px;">Add new entry</h3>
                                    <v-textarea outlined name="input-7-4" color="success" v-model="newWorkLog"
                                        @keydown="workLogKeys($event)">
                                    </v-textarea>
                                    <v-alert v-if="newWorkLogFeedback" type="success" style="margin-top: 10px;">
                                        {{newWorkLogFeedback}}
                                    </v-alert>
                                    <v-alert v-if="newWorkLogFeedbackErr" type="error" style="margin-top: 10px;">
                                        {{newWorkLogFeedbackErr}}
                                    </v-alert>
                                    <v-btn color="success" style="float: right" @click="addNewWorkLog()">Add New</v-btn>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel class="customer-details" v-if="isAdmin">
                        <v-expansion-panel-header>Customer details</v-expansion-panel-header>
                        <ul class="c-details" v-if="this.customerInfo">
                        <li>Name: {{this.customerInfo.name}}</li>
                        <li>Email: {{this.customerInfo.email}}</li>
                        <li>Company: {{this.customerInfo.company}}</li>
                        <li>Country: {{this.customerInfo.address_country}}</li>
                        <li>Last sign in time: {{this.customerInfo.lastSignInTime}}</li>
                        <li>Ecommerce Support: <span style="color: #4caf50;" v-if="site.plan.ecommerce_support != 0">Yes</span> <span v-if="site.plan.ecommerce_support == 0" style="color: red;">No</span></li>
                        </ul>
                        <br>
                        <v-expansion-panel-content>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import firebase from '@/firebase.js'
    import moment from 'moment'
    export default {
        props: ["siteId"],
        name: 'Site',
        components: {},
        data() {
            return {
                site: null,
                customerInfo: null,
                panel: 2,
                reply: null,
                feedback: null,
                newWorkLog: null,
                newWorkLogFeedback: null,
                newWorkLogFeedbackErr: null,
                accessDetails: null,
                accessDetailsFeedback: null,
                accessDetailsFeedbackErr: null,
                statusFeedback: null,
                statusFeedbackErr: null,
                workLog: [],
                messages: [],
                isAdmin: null,
                statusSelects: [{
                        text: 'Received and in progress',
                        value: 'received_and_in_progress'
                    },
                    {
                        text: 'Awaiting customer',
                        value: 'awaiting_customer'
                    }
                ]
            }
        },
        methods: {
            chatKeys(e) {
                if (e.key == 'Enter' && e.altKey == false && e.ctrlKey == false && e.shiftKey == false) {
                    return this.addMessage();
                }
                // if SHIFT + ENTER add new row
                if (e.key == 'Enter' && e.altKey == false && e.ctrlKey == false && e.shiftKey == true) {
                    return
                }
            },
            workLogKeys(e) {
                if (e.key == 'Enter' && e.altKey == false && e.ctrlKey == false && e.shiftKey == false) {
                    return this.addNewWorkLog();
                }
                // if SHIFT + ENTER add new row
                if (e.key == 'Enter' && e.altKey == false && e.ctrlKey == false && e.shiftKey == true) {
                    return
                }
            },
            addMessage() {
                if (this.reply && this.reply != '') {
                    // array of objects
                    if (this.siteId) {
                        firebase.firestore().collection('sites').doc(this.siteId).update({
                            chat: firebase.firestore.FieldValue.arrayUnion({
                                reply: this.reply,
                                date: Date.now(),
                                uid: this.$store.state.user.uid,
                                name: this.$store.state.user.displayName
                            })
                        }).then(() => {
                            firebase.firestore().collection('sites').doc(this.siteId).update({
                                status: 'received_and_in_progress'
                            })
                            this.reply = null;
                            this.feedback = null;
                        }).catch((err) => {
                            this.feedback = 'Sorry! An error happened: ' + err;
                        })
                    }
                } else {
                    this.feedback = 'You must enter a message in order to send one :)'
                }
            },
            saveAccessDetails() {
                if (this.siteId) {
                    let siteRef = firebase.firestore().collection('sites').doc(this.siteId);
                    siteRef.update({
                        accessDetails: this.accessDetails
                    }).then(() => {
                        this.accessDetailsFeedback = 'Details saved'
                    }).catch(err => {
                        this.accessDetailsFeedbackErr = 'Error: ' + err;
                    })
                }
            },
            addNewWorkLog() {
                if (this.newWorkLog) {
                    // array of objects
                    if (this.siteId) {
                        firebase.firestore().collection('sites').doc(this.siteId).update({
                            workLog: firebase.firestore.FieldValue.arrayUnion({
                                entry: this.newWorkLog,
                                date: Date.now(),
                                uid: this.$store.state.user.uid
                            })
                        }).then(() => {
                            this.newWorkLog = ''
                            this.newWorkLogFeedback = 'Entry added!'
                            this.newWorkLogFeedbackErr = null;
                        }).catch((err) => {
                            this.newWorkLogFeedbackErr = 'Error happened: ', err;
                            this.newWorkLogFeedback = null
                        })
                    }
                } else {
                    this.newWorkLogFeedbackErr = 'You should enter here something if you wish to add a new log :)'
                    this.newWorkLogFeedback = null
                }
            },
            changeStatus(e) {
                if (this.siteId) {
                    firebase.firestore().collection('sites').doc(this.siteId).update({
                        status: e
                    }).then(() => {
                        // feedback that status changed
                        this.statusFeedback = "Status changed!";
                        this.statusFeedbackErr = null;
                    }).catch((err) => {
                        // feedback some error
                        this.statusFeedback = null;
                        this.statusFeedbackErr = "Status error: " + err;
                    })
                }
            },
            customerDetails() {
                if (this.siteId) {
                    firebase.firestore().collection('sites').doc(this.siteId).get().then((doc) => {
                    let data = doc.data()
                    firebase.firestore().collection('users').doc(data.uid).get().then((userData) => {
                        this.customerInfo = userData.data()
                    })
                    })
                }
            }
        },
        computed: {
            
        },
        filters: {
            formatDate(value) {
            if (!value) return ''
            return moment(value).format('lll')
            }
        },
        created() {
            let userUid = firebase.auth().currentUser.uid;
            let siteRef = firebase.firestore().collection('sites').doc(this.siteId);
            let userRef = firebase.firestore().collection('users').doc(userUid);
            siteRef.onSnapshot((snapshot) => {
                let siteData = snapshot.data()

                this.site = siteData
                this.messages = siteData.chat
                this.workLog = siteData.workLog
                this.accessDetails = siteData.accessDetails
            });
            userRef.get().then((userData) => {
                let data = userData.data()
                if (data.admin) {
                    this.isAdmin = true;
                }
                if (!data.admin) {
                    this.isAdmin = false;
                }
            })

            this.customerDetails()
        }
    }
</script>

<style scoped>
    ul.messages {
        height: calc(80vh - 100px);
        overflow: auto;
        list-style-type: none;
        padding: 20px;
    }

    ul.work-log {
        max-height: 200px;
        overflow: auto;

    }

    li.message {
        margin: 15px 0px 15px 0px;
    }

    .reply {
        margin-top: 14px;
        border-radius: 10px;
        padding: 24px;
        background-color: rgb(246, 245, 247);
        margin: 0px 0px 0px 0px;
        white-space: pre-wrap;
        clear: both;
    }

    /* Chat Scrollbar*/
    ul.messages::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #F5F5F5;
        border-radius: 10px;
    }

    ul.messages::-webkit-scrollbar {
        width: 15px;
        background-color: #F5F5F5;
    }

    ul.messages::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #fff;
        background-image: -webkit-linear-gradient(top,
                #0b2540 0%,
                #348bc0 100%);
    }

    /* Chat Scrollbar End*/
    /* WorkLog Scrollbar*/
    ul.work-log::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #F5F5F5;
        border-radius: 10px;
    }

    ul.work-log::-webkit-scrollbar {
        width: 15px;
        background-color: #F5F5F5;
    }

    ul.work-log::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #fff;
        background-image: -webkit-linear-gradient(top,
                #0b2540 0%,
                #348bc0 100%);
    }

    /* WorkLog Scrollbar End*/
    .user {
        font-weight: 600;
        color: #4a4a4a;
        font-size: 16px;
    }

    .mine .reply {
        border: 1px #5c8ed8 solid;
        color: #000;
    }

    .not-mine .reply {
        border: 1px #75af71 solid;
        background-color: #fff;
        color: #000;
    }
    .c-details {
        list-style: none;
    }
    .messages >>> table {
        width: 100%;
        border: none;
    }
</style>
