<template>
<div class="Plans">
    <h2>Plans</h2>
</div>
</template>

<script>

  export default {
    data: () => ({
     
    }),
    methods: {
    }
  }
</script>

<style>

</style>
