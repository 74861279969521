<template>
  <v-container class="sign-up-container" fluid fill-height>
    <v-row class="sign-up-row" fluid fill-height>
      <v-col cols="0" sm="8" lg="9" class="sign-up" fill-height></v-col>
      <v-col cols="12" sm="4" lg="3" class="sign-up-form-container">
        <v-form ref="form" @keydown.native.enter.prevent="validate" class="form" v-model="valid" lazy-validation>
          <div style="width: 100%; padding: 80px 0 25px 0; text-align: center;">
            <img style="max-width: 75px;" src="~@/assets/cat-logo.png">
          </div>
          <h2 style="text-align: center;">Sign Up</h2>
          <p style="text-align: center; margin: 5px 0 30px 0;">Let's get your website fixed!</p>
          <v-alert v-if="feedbackErr" type="error">
            {{feedbackErr}}
          </v-alert>
          <v-text-field autocomplete="new" v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
          <v-text-field autocomplete="new" v-model="password" :append-icon="show1 ? 'visibility' : 'visibility_off'"
            :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password"
            hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
          <v-checkbox v-model="checkbox" :rules="[v => !!v || 'You must agree to continue!']"
            label="Do you agree with the terms & conditions?" required @change="$v.checkbox.$touch()"
            @blur="$v.checkbox.$touch()">
            <div slot="label">Do you agree with the
              <a href="https://supportmeow.com/terms-of-service/" target="_blank" @click.stop>Terms & Conditions?</a>
            </div>
          </v-checkbox>

          <div class="text-center">
            <v-btn  v-if="!loading" :disabled="!valid" style="margin: auto; margin-top: 10px;" color="success" large block class="mr-4"
              @click="validate">
              Sign Up!
            </v-btn>
            <div class="loading" v-if="loading" style="margin: 10px;">
              <v-progress-circular indeterminate color="green"></v-progress-circular>
              &nbsp;Loading...
            </div>
            <div style="margin-top: 20px;">
              <a href="/login">Already have an account?</a>
            </div>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import firebase from '@/firebase.js'

  export default {
    data: () => ({
      show1: false,
      valid: true,
      email: '',
      name: '',
      feedbackErr: null,
      checkbox: false,
      loading: false, 
      password: '',
      nameRules: [
        v => !!v || 'Name is required'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      /* rules are default and not configured yet */
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => ('The email and password you entered don\'t match'),
      }
    }),
    methods: {
      validate() {
        if (this.$refs.form.validate()) {
          this.snackbar = true
          this.loading = true;

          firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
            .then(cred => {
              let addUserData = firebase.functions().httpsCallable('addUserData')
              addUserData({
                email: this.email,
                uid: cred.user.uid,
                creationTime: cred.user.metadata.creationTime,
                lastSignInTime: cred.user.metadata.lastSignInTime
              }).then(() => {
                this.$router.push('/setup')
              }).catch(err => {
                this.loading = false;
                this.feedbackErr = 'Unexpected error happened: ' + err;
              })
            })
            .catch(err => {
              this.loading = false;
              this.feedbackErr = err.message
            })
        }
      }
    }
  }
</script>

<style scoped>
  .sign-up-container {
    padding: 0;
  }
  .sign-up-row {
    height: 100%;
    padding: 0;
    margin: 0;
  }
  .sign-up {
    background: url('~@/assets/login-bg.png') no-repeat fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 0;
    margin: 0;
  }

  .form {
    width: 100%;
    padding: 10%;
    position: relative;
    background-color: #fff;
  }
  .sign-up-form-container {
    background-color: #fff;
  }

  /* Fix autofill issues */

  
</style>