<template>
      <v-container class="sign-up-container" fluid fill-height>
         <v-row class="sign-up-row" fluid fill-height>
           <v-col cols="0" sm="8" lg="9" class="sign-up" fill-height></v-col>
           <v-col cols="12" sm="4" lg="3" class="sign-up-form-container">
                <v-form ref="form" @keydown.native.enter.prevent="validate" class="form" v-model="valid" style="margin: auto;" lazy-validation>
                    <div style="width: 100%; padding: 80px 0 25px 0; text-align: center;">
                    <img style="max-width: 75px;" src="~@/assets/cat-logo.png">
                </div>
                    <v-alert v-if="feedback" type="success">
                    Password Reset Email Sent!
                    </v-alert>
                    <v-alert v-if="error" type="error">
                        {{error}}
                    </v-alert>
                    <h2 style="text-align: center;">Forgot Your Password?</h2>
                    <p style="text-align: center; margin-top: 20px;">Fill in your email and we will send you a new password.</p>
                    <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
                    <div class="text-center">
                        <v-btn :disabled="!valid" style="margin: auto; margin-top: 10px;" color="success" large block
                            class="mr-4" @click="validate">
                            Send password
                        </v-btn>
                        <div style="margin-top: 20px;">
                            <a href="/login">Back To Login</a>
                        </div>
                    </div>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import firebase from '@/firebase.js'

    export default {
        data: () => ({
            email: '',
            feedback: null,
            error: null,
            valid: true,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ]
        }),
        methods: {
            validate() {
                if (this.$refs.form.validate()) {
                    this.snackbar = true
                    // [START sendpasswordemail]
                    firebase.auth().sendPasswordResetEmail(this.email).then(() => {
                        // Password Reset Email Sent!
                        // [START_EXCLUDE]
                        this.feedback = true;
                        this.error = false;

                        // [END_EXCLUDE]
                    }).catch((error) => {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        // [START_EXCLUDE]
                        if (errorCode == 'auth/invalid-email') {
                            this.feedback = false;
                            this.error = errorMessage
                        } else if (errorCode == 'auth/user-not-found') {
                            this.feedback = false;
                            this.error = errorMessage
                        }
                        // [END_EXCLUDE]
                    });
                    // [END sendpasswordemail];
                }
            }
        }
    }
</script>

<style scoped>
  .sign-up-container {
    padding: 0;
  }
  .sign-up-row {
    height: 100%;
    padding: 0;
    margin: 0;
  }
 .sign-up {
    background: url('~@/assets/login-bg.png') no-repeat fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 0;
    margin: 0;
  }
  
  .form {
    width: 100%;
    padding: 10%;
    position: relative;
    background-color: #fff;
  }
  .sign-up-form-container {
    background-color: #fff;
  }
</style>