<template>
  <v-row class="setup" no-gutters justify="center" align="center">
    <v-col cols="12" xl="6" lg="12">
      <div :class="{
          'setup-form': 'setup-form',
          'setup-form-xs': $vuetify.breakpoint.xs,
          'setup-form-sm': $vuetify.breakpoint.sm,
          'setup-form-md': $vuetify.breakpoint.md,
          'setup-form-lg': $vuetify.breakpoint.lg,
          'setup-form-xl': $vuetify.breakpoint.xl,
          }" style="background-color: rgba(0,0,0,0.75); padding: 30px;"
          
          >
        <h2 style="color: white; text-align: center; margin-bottom: 20px;">You Are Almost Ready To Start Your Support Journey With Us</h2>
        <p style="color: white; text-align: center;">Please fill in your basic information before you can continue to add a site to be supported</p>
        <v-form ref="form" style="margin-top: 10px;">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field class="setup-field" color="#00E676" v-model="name" label="Your Name*" outlined required
                :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field class="setup-field" color="#00E676" v-model="company" label="Company Name" outlined>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field class="setup-field" color="#00E676" v-model="address1" label="Address*" outlined required
                :rules="[rules.required]">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field class="setup-field" color="#00E676" v-model="city" label="City*" outlined required
                :rules="[rules.required]"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-text-field class="setup-field" color="#00E676" v-model="state" label="State*" outlined required
                :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-text-field class="setup-field" color="#00E676" v-model="country" label="Country*" outlined required
                :rules="[rules.required]">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="!stripeLoaded" style="margin: auto;">
            <v-col class="credit-card" cols="12" sm="12" md="6">
            <v-progress-circular
            indeterminate
            color="green"
            center
            
           ></v-progress-circular>
           </v-col>
           </v-row>
          <v-row v-if="stripeLoaded" class="stripe-container">
            <v-col class="credit-card" cols="12" sm="12" md="6">
              <h3 style="color: white; margin-bottom: 10px; margin-top: 10px;">Please add a payment method</h3>
                <div class="payment-simple" style="max-width: 450px">
                  <StripeElements
                    :stripe-key="stripeKey"
                    :instance-options="instanceOptions"
                    :elements-options="elementsOptions"
                    #default="{ elements }"
                    ref="elms"
                  >
                    <StripeElement
                      type="card"
                      :elements="elements"
                      :options="cardOptions"
                      ref="card"
                      :class='{ complete }'
                      @change='complete = $event.complete'
                    />
                  </StripeElements>
              <v-btn class='pay-with-stripe' @click="submitNewCreditCard" type="button" style="margin-top: 20px;" color="success" :disabled='!complete'>Save Your Info</v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" style="color: white;">
              <v-alert v-show="feedback" type="error">{{feedback}}</v-alert>
              <div v-show="!feedback" class="notice" style="color: white;">Your card won't be charged until you add a site to be supported.</div>
            </v-col>
          </v-row>
        </v-form>
        <v-row style="margin-top: 0px;">
          <v-col cols="12" sm="12" md="6">
              <div class="loading" v-if="loading" style="margin-top: 80px;">
                <v-progress-circular indeterminate color="green"></v-progress-circular>
                <span style="color: white;">&nbsp;&nbsp;Loading...</span>
              </div>
          </v-col>
          <v-col cols="12" sm="12" md="6">
              <div class="secure" style="color: white; font-weight: 500; float: right; font-size: 14px;">
              <v-icon color="green" size="30">security</v-icon>&nbsp;Secure Card Payments Provided By Stripe
            </div>
          </v-col>
        </v-row>

      </div>
    </v-col>
  </v-row>
</template>

<script>
  import firebase from '@/firebase.js'
  import store from '@/store'
  import { StripeElements, StripeElement} from 'vue-stripe-elements-plus'

  export default {
    data() {
      return {
        name: null,
        company: null,
        address1: null,
        city: null,
        state: null,
        country: null,
        complete: false,
        feedback: null,
        loading: false,
        client_secret: null,
        stripeKey: null,
        stripeLoaded: false,
        rules: {
          required: value => !!value || 'Required.'
        },
        instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
        },
        elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
        },
        cardOptions: {
          style: {
            base: {
              color: '#00E676',
              '::placeholder': {
                color: '#00E676',
              },
            },
            empty: {
              color: '#fff'
            },
            invalid: {
              color: 'red',
              ':focus': {
                color: 'red',
              },
            },
          }

          // see https://stripe.com/docs/stripe.js#element-options for details
        }
      }
    },

    components: {
      /*Card*/
      StripeElements,
      StripeElement
    },
    created() {
      let getStripePublic = firebase.functions().httpsCallable('getStripePublic')
      getStripePublic().then((result) => {
        this.stripeKey = result.data;
        this.stripeLoaded = true;
      })

      let setupIntent = firebase.functions().httpsCallable('setupIntent')
      setupIntent().then((result) => {
        this.client_secret = result.data.client_secret;
      })

    },
    methods: {
      submitNewCreditCard() {
      
      const groupComponent = this.$refs.elms
      const cardComponent = this.$refs.card
      // Get stripe element
      const cardElement = cardComponent.stripeElement


        if (this.$refs.form.validate()) {
          this.loading = true;
          let user = firebase.auth().currentUser

          groupComponent.instance.handleCardSetup(this.client_secret, cardElement).then((result) => {

            if (result.error) {
                // Display error.message in your UI.
            this.feedback = result.error.message;
            this.loading = false;
            } else {
            let createStripeCustomer = firebase.functions().httpsCallable('createStripeCustomer')

            let StripeCustomerData = {
              address: {
                line1: this.address1,
                city: this.city,
                country: this.country,
                postal_code: this.postal_code,
                state: this.state
              },
              metadata: {
                company: this.company || false
              },
              name: this.name,
              setupIntent: result.setupIntent,
              user: user.uid,
              email: user.email
            }
            createStripeCustomer(StripeCustomerData).then((result) => {
              
              if (result.error) {
                // Display error.message in your UI.
                this.feedback = result.error.message;
                this.loading = false;

              } else {
                  user.updateProfile({
                    displayName: this.name,
                  }).then(() => {
                    this.$router.push({
                      name: 'AddNewSite'
                    })

                  // send email verification.
                  var user = window.firebase.auth().currentUser;
                  user.sendEmailVerification()
                  store.commit('askEmailVerification', true)

                  })
              }
            })
            }
          })
        }
      }
    }
  }
</script>

<style scoped>
  .setup {
    background: url('~@/assets/Sea-with-the-ship.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: calc(100vh - 64px);
  }

  .stripe-card {
    width: 100%;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: rgba(255, 255, 255, 0) !important;
  }

  .row>>>fieldset {
    border-color: #00E676 !important;
    border: solid 1px;
  }

  .row>>>input {
    color: #fff !important;
  }

  .row>>>label {
    color: #00E676 !important;
  }

  label.v-label.theme--light {
    color: #00E676 !important;
  }

  .theme--light.v-input:not(.v-input--is-disabled) input {
    color: white;
  }

  .setup-form .col-md-6.col-12 {
    max-height: 70px;
  }

  /* Form css */
  .setup-form-xs {
    padding: 25px 20px 80px 20px !important;
  }

  .setup-form-sm {
    padding: 25px 20px 30px 20px !important;
  }

  .setup-form-md {
    padding: 25px 20px 80px 20px !important;
  }
  .setup-form-lg {
    padding: 25px 20px 90px 20px !important;
  }
  .setup-form-xl {
    padding: 25px 20px 100px 20px !important;
  }
  .setup-form-xs .notice {
    margin-top: 70px;
  }
  .setup-form-sm .notice {
    margin-top: 70px;
  }
  .setup-form-md .notice {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .setup-form-lg .notice {
    margin-top: 45px;
    margin-bottom: 20px;
  }
  .setup-form-xl .notice {
    margin-top: 45px;
    margin-bottom: 20px;
  }
  .setup-form-xs .secure {
    margin-top: 65px;
    margin-right: 15px;
  }
  .setup-form-sm .secure {
    margin-top: 35px;
  }
  .setup-form-md .secure {
    margin-top: 80px;
  }
  .setup-form-lg .secure {
    margin-top: 80px;
  }
  .setup-form-xl .secure {
    margin-top: 90px;
  }
  

  /* Chrome autofill */
  .row>>>input:-webkit-autofill,
  .row>>>input:-webkit-autofill:hover,
  .row>>>input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s !important;
    -webkit-text-fill-color: #fff;
  }
</style>