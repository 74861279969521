<template>
  <v-app>
    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
import store from '@/store'
import firebase from 'firebase'

export default {
  name: 'App',
  components: {
  },
  data: () => ({
    //
  }),
    computed: {
      getUser () {
        return this.$store.state.user
      }
    },
    mounted() {   
      if (firebase.auth().user)  {
        store.dispatch('commitSites')
      }
      
    }
};
</script>

<style>
   /* Drawer Scrollbar*/
     ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar {
        width: 15px;
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #fff;
        background-image: -webkit-linear-gradient(top,
                #0b2540 0%,
                #348bc0 100%);
    }
    /* Drawer Scrollbar End*/
</style>