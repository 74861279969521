<template>
    <div>
      <h2>Settings</h2>


      <h2>Your basic information</h2>
      <p>Company Name</p>
      <p>First Name</p>
      <p>Last Name</p>
      <p>Address 1</p><p>Address 2</p>
      <p>City</p>
      <p>State / Province</p>
      <p>Zip / Postal Code</p>
      <p>Country</p>
      <p>VAT / Tax ID / number (leave empty if none)</p>

      <h2>Your payment information</h2>
      Your card won't be charged for 7 days. If you cancel before then, your card won't be charged.

      Secure server
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>