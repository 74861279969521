<template>
  <v-container class="grey lighten-5" fluid pa-0>
    <v-row no-gutters>
        <v-col cols="12" md="2">
        <Menu />
      </v-col>
      <v-col cols="12" md="10">
        <NavBar />
        <router-view :key="$route.fullPath" style="padding: 20px;" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NavBar from '../components/layout/NavBar.vue';
import Menu from '../components/admin/Menu.vue';

 export default {
    name: 'Admin',
    components: {
      NavBar,
      Menu
    },
    computed: {
      getUser () {
        return this.store.state.user
      }
    }
  };
</script>

<style>

</style>