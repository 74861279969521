<template>
    <div>
        <v-card-title style="margin-left: -15px;">Customers
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="sites" :items-per-page="5" class="elevation-1" :search="search"
            @click:row="showSite">
        </v-data-table>
    </div>
</template>

<script>
    import firebase from '@/firebase.js'

    export default {
        data: () => ({
            search: '',
            headers: [{
                    text: 'Website',
                    align: 'left',
                    sortable: false,
                    value: 'site',
                },
                {
                    text: 'Status',
                    align: 'left',
                    value: 'site',
                },
                {
                    text: 'Next Maintenance',
                    align: 'left',
                    value: 'site',
                },
                {
                    text: 'Value',
                    align: 'left',
                    value: 'plan.total',
                },
            ],
            sites: []
        }),
        methods: {
            showSite(e) {
                this.$router.push('/site/' + e.siteId)
            }
        },
        created() {
            firebase.firestore().collection('sites').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    let siteData = doc.data();
                    let siteId = doc.id;
                    let site = {
                        site: siteData.site,
                        plan: siteData.plan,
                        siteId: siteId
                    }

                    this.sites.push(site)
                });
            });

        }
    }
</script>


<style scoped>

</style>