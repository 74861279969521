<template>
  <v-container class="sign-up-container" fluid fill-height>
    <v-row class="sign-up-row" fluid fill-height>
      <v-col cols="0" sm="8" lg="9" class="sign-up" fill-height></v-col>
      <v-col cols="12" sm="4" lg="3" class="sign-up-form-container">
        <v-form ref="form" @keydown.native.enter.prevent="login" class="form" v-model="valid"
          style="margin: auto;" lazy-validation>
          <div style="width: 100%; padding: 80px 0 25px 0; text-align: center;">
            <img style="max-width: 75px;" src="~@/assets/cat-logo.png">
          </div>
          <v-alert v-if="feedback" type="error">
          {{feedback}}
          </v-alert>
          <h2 style="text-align: center;">Log in</h2>
          <p style="text-align: center;">Let's get your website fixed!</p>
          <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
          <v-text-field v-model="password" :append-icon="show1 ? 'visibility' : 'visibility_off'"
            :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password"
            hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
          <div class="text-center" style="margin-top: 10px;">
          <v-btn :disabled="!valid" v-if="!loading" style="margin: auto;" color="success" large block class="mr-4" @click="login">
            Log In
          </v-btn>
          <div class="loading" v-if="loading" style="margin: 10px;">
            <v-progress-circular indeterminate color="green"></v-progress-circular>
            &nbsp;Loading...
          </div>
          <div style="margin-top: 20px;">
          <p> Or...</p>
          <a href="/signup">Sign up</a>
          <div style="padding-bottom: 10px"></div>
          <a style="margin-top: 20px" href="/forgotpassword">I forgot my password</a>
          </div>
          </div>
        </v-form>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from 'firebase'
  export default {
    data: () => ({
      show1: false,
      valid: true,
      email: '',
      password: '',
      feedback: null,
      loading: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => ('The email and password you entered don\'t match'),
      },
      checkbox: false,
    }),

    methods: {
      login() {
        if (this.$refs.form.validate()) {
          this.snackbar = true
          this.loading = true;

          firebase.auth().signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            this.$router.push({ name: 'AddNewSite' })
          }).catch(err => {
            this.loading = false
            this.feedback = err.message;
          })
        } else {
          this.feedback = 'Please fill in all the fields'
          this.loading = false

        }
      }
    },
  }
</script>

<style scoped>
  .sign-up-container {
    padding: 0;
  }
  .sign-up-row {
    height: 100%;
    padding: 0;
    margin: 0;
  }
 .sign-up {
    background: url('~@/assets/login-bg.png') no-repeat fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 0;
    margin: 0;
  }
  
  .form {
    width: 100%;
    padding: 10%;
    position: relative;
    background-color: #fff;
  }
  .sign-up-form-container {
    background-color: #fff;
  }

  @media only screen and (max-width: 425px) {
  .sign-up {
    display: none !important;
  }
  }
</style>