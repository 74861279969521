import firebase from 'firebase'
import 'firebase/firestore';

const settings = {};
let config;
if (window.location.host == 'support-meow-dev.firebaseapp.com' || window.location.host == 'localhost:8080') {
// Dev config
config = {
  apiKey: "AIzaSyA-v2yM0QTH2hsoMAqCOxnoEJJ0rnwBZt4",
  authDomain: "support-meow-dev.firebaseapp.com",
  databaseURL: "https://support-meow-dev.firebaseio.com",
  projectId: "support-meow-dev",
  storageBucket: "support-meow-dev.appspot.com",
  messagingSenderId: "352172555749",
  appId: "1:352172555749:web:f4fad028d3d1d01d0dc30c"
};
} else {
// Prod conf
config = {
    apiKey: "AIzaSyDOkfWCF2zJhZw16qvLn2KIAJNyMGA2bMc",
    authDomain: "support-meow-customer.firebaseapp.com",
    databaseURL: "https://support-meow-customer.firebaseio.com",
    projectId: "support-meow-customer",
    storageBucket: "support-meow-customer.appspot.com",
    messagingSenderId: "523668570789",
    appId: "1:523668570789:web:2f1147ef97f0c33a8c034c"
  };

}



// Initialize Firebase
firebase.initializeApp(config);

firebase.firestore().settings(settings);


export default firebase;