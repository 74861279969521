import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    sites: [],
    emailVerification: false
  },
  mutations: {
    updateUser (state, { user }) {
      Vue.set(state, 'user', user)
    },
    logOutUser (state, payload) {
      Vue.set(state, 'user', payload)
    },
    setSites (state, payload) {
      Vue.set(state, 'sites', payload)
    },
    unSetSites (state, payload) {
      Vue.set(state, 'sites', payload)
    },
    askEmailVerification (state, payload) {
      Vue.set(state, 'emailVerification', payload)
    },
  },
  actions: {
    commitSites ({ commit, state }) {
      
      firebase.firestore().collection("sites").where("uid", "==", state.user.uid)
     .onSnapshot(querySnapshot => {
      let userSites = {};
       querySnapshot.forEach(doc => {
             let docId = doc.id;
             let site = doc.data().site
             let uid = doc.data().uid
             let plan = doc.data().plan
             let accessDetails = doc.data().accessDetails
             let siteObject = {
               site: site,
               docId: doc.id,
               uid: uid,
               plan: {
                plan
               },
               accessDetails: accessDetails
             }
             userSites[docId] = siteObject;
       })
       commit('setSites', userSites)
   })

    }
  },
  getters: {
    user: state => state.user,
    sites: state => state.sites
  }
})
